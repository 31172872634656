import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = ({ onToggleMenu }) => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <strong>NH</strong>
    </Link>
    {/* <nav>
      <a
        className="menu-link"
        onClick={e => {
          e.preventDefault()
          onToggleMenu()
        }}
        href="#"
      >
        Menu
      </a>
    </nav> */}
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
