import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = ({ onToggleMenu }) => (
  <nav id="menu">
    <div className="inner">
      {/* <ul className="links">
        <li>
          <Link onClick={onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={onToggleMenu} to="/about">
            About
          </Link>
        </li>
        <li>
          <Link onClick={onToggleMenu} to="/work">
            Work
          </Link>
        </li>
        <li>
          <Link onClick={onToggleMenu} to="/personal">
            Personal
          </Link>
        </li>
        <li>
          <Link onClick={onToggleMenu} to="/contact">
            Contact
          </Link>
        </li>
      </ul> */}
    </div>
    <a
      className="close"
      onClick={e => {
        e.preventDefault()
        onToggleMenu()
      }}
      href="#"
    >
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
