import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  // const siteTitle = data.site.siteMetadata.title

  const [loading, setLoading] = useState(true)
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const bodyClass = classnames('body', {
    'is-loading': loading,
    'is-menu-visible': isMenuVisible,
  })

  return (
    <div className={bodyClass}>
      <div id="wrapper">
        <Header onToggleMenu={handleToggleMenu} />
        {children}
        {/* <Contact /> */}
        <Footer />
      </div>
      <Menu onToggleMenu={handleToggleMenu} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
