import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://www.linkedin.com/in/natehorstmann/"
            className="icon alt fa-linkedin"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/natehorstmann/"
            className="icon alt fa-github"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/natehorstmann/"
            className="icon alt fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/natehorstmann"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/natehorstmann/"
            className="icon alt fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; {new Date().getFullYear()} Nate Horstmann</li>
        {/* <li>
          Design Based On: <a href="https://html5up.net">HTML5 UP</a>
        </li>
        <li>
          Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </li> */}
      </ul>
    </div>
  </footer>
)

export default Footer
